import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllAboutUs } from '../store/actions/aboutUsActions'
import { Divider } from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone'
import LocationOnIcon from '@material-ui/icons/LocationOn'
// import MailIcon from '@material-ui/icons/Mail'
import { useCurrentWidth } from '../components/screenWidth'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import SwiperCore, { Pagination, Navigation } from 'swiper/core'

SwiperCore.use([Pagination, Navigation])

const PersonList = ({ person }) => {
    return (
        <div className="aboutus_team_person">
            <div className="aboutus_team_person_avatar">
                <img src={person.avatar} alt="avatar" />
            </div>
            <div className="aboutus_team_person_name">{person.name}</div>
            <div className="aboutus_team_person_title">{person.title}</div>
        </div>
    )
}

const AboutUs = () => {
    const dispatch = useDispatch()
    const aboutUsState = useSelector((state) => state.aboutUs)
    const { header, headOffices, teamMembers } = aboutUsState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!header || !headOffices || !teamMembers) {
            dispatch(getAllAboutUs())
        }
    }, [dispatch, header, headOffices, teamMembers])
    const screenWidth = useCurrentWidth()

    return (
        <>
            {header && (
                <Container disableGutters={true}>
                    <div
                        className="aboutus_header"
                        style={{
                            background: `url(${header.bigPicLink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="aboutus_slogan">
                            <div className="aboutus_slogan_word">
                                {header.slogan}
                            </div>
                        </div>
                    </div>
                </Container>
            )}
            <Container disableGutters={true}>
                <div className="aboutus_gettoknow">
                    <div className="aboutus_gettoknow_content">
                        <div className="home_pic_point_title_area">
                            <div>
                                <h1>Get to Know Us</h1>
                            </div>
                            <div className="home_pic_point_yellow_line"></div>
                        </div>

                        <p>
                            Welcome to Hspace Kitchen and Bath, your dedicated
                            E-Commerce store that makes online shopping for your
                            kitchen easy and personalized. Why wouldn’t we? With
                            over 40 years of quality service in cabinetry, we
                            know that kitchens are the heart of the home.
                        </p>
                        <p>
                            Customers are able to choose from ready-to-install
                            cabinets manufactured from high-quality
                            craftsmanship, to ensure that our products are
                            always in style. Using our web design tool, the
                            2020® Design System, customers can upload their
                            kitchen floor plan into our design center with the
                            assistance of one of our experienced designers, free
                            of charge, and design a kitchen that best suits
                            their needs.
                        </p>
                        <p>
                            Our best price guarantee offers complete product
                            price transparency, allowing customers to compare
                            product prices in order to receive the best prices
                            in town. Once orders are placed, customers receive
                            assembled cabinets by way of quick and reliable
                            shipping.
                        </p>
                        <p>
                            To get started, either utilize our online chatting
                            service for a quick response, or click on “free
                            consultation” to meet with one of our experts.
                        </p>
                    </div>
                </div>
            </Container>
            {headOffices && (
                <Container disableGutters={true}>
                    {headOffices.map((headOffice) => (
                        <div key={headOffice.id}>
                            <div className="aboutus_office_box">
                                <div className="aboutus_office_title">
                                    <div className="aboutus_office_title_word">
                                        {headOffice.location}
                                    </div>
                                    <div className="aboutus_office_title_line"></div>
                                </div>
                                <div className="aboutus_info">
                                    <div className="aboutus_info_word">
                                        <PhoneIcon
                                            style={{ marginRight: '10px' }}
                                        />{' '}
                                        {headOffice.phone}
                                    </div>
                                    <div className="aboutus_info_word">
                                        <LocationOnIcon
                                            style={{ marginRight: '10px' }}
                                        />{' '}
                                        {headOffice.address}
                                    </div>
                                    {/* <div className="aboutus_info_word">
                                        <MailIcon
                                            style={{ marginRight: '10px' }}
                                        />{' '}
                                        {headOffice.email}
                                    </div> */}
                                    <div className="aboutus_info_word">
                                        <a href={`tel:${headOffice.phone}`}>
                                            <button className="aboutus_info_word_button">
                                                Contact us
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="aboutus_office_map">
                                    <img
                                        src={`https://www.mapquestapi.com/staticmap/v5/map?key=${process.env.REACT_APP_MAPQUEST_KEY}&center=${headOffice.address}&size=300,200@2x`}
                                        alt="map"
                                        height="250px"
                                        style={{ objectFix: 'contain' }}
                                    />
                                </div>
                            </div>

                            <Divider />
                        </div>
                    ))}
                </Container>
            )}
            {teamMembers && (
                <Container disableGutters={true}>
                    <div className="aboutus_team_box">
                        <div className="aboutus_team_title">Meet our team</div>
                        {screenWidth > 768 ? (
                            <div className="aboutus_team_person_area">
                                {teamMembers.map((member) => (
                                    <PersonList
                                        key={member.id}
                                        person={member}
                                    />
                                ))}
                            </div>
                        ) : (
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                slidesPerGroup={1}
                                loop={true}
                                loopFillGroupWithBlank={true}
                                navigation={true}
                                className="mySwiper"
                            >
                                {teamMembers?.map((member) => (
                                    <SwiperSlide key={member.id}>
                                        <PersonList person={member} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </Container>
            )}
        </>
    )
}

export default AboutUs
