import { Container } from '@material-ui/core'
import React, { useEffect } from 'react'
import DesignCenter from '../components/DesignCenter'
import { useCurrentWidth } from '../components/screenWidth'
import history from '../history'

const promoList = [
    { id: 1, title: 'spend up to $3,000', promo: '10%', boxColor: '#EDC400' },
    {
        id: 2,
        title: 'spend up to $3,001 - $5,500',
        promo: '20%',
        boxColor: '#EDC400',
    },
    {
        id: 3,
        title: 'spend up to $5,501 - $7,500',
        promo: '25%',
        boxColor: '#EDC400',
    },
    {
        id: 4,
        title: 'spend up to $7,501 - $10,000',
        promo: '30%',
        boxColor: '#EDB600',
    },
    {
        id: 5,
        title: 'spend up to $10,001 - $15,000',
        promo: '35%',
        boxColor: '#ED9B00',
    },
    {
        id: 6,
        title: 'spend up to $15,001 or more',
        promo: '40%',
        boxColor: '#ED7000',
    },
]

const BoxingMonthPromotion = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const screenWidth = useCurrentWidth()
    return (
        <>
            <div className="boxing_container">
                <div
                    className="boxing_bgpic"
                    style={{
                        backgroundImage: `url(${
                            screenWidth < 769
                                ? 'https://res.cloudinary.com/home-space/image/upload/c_scale,w_900/v1668031705/Products/Hspace%20Special%20Deals/2022_Boxing_Month_Promotion/%E7%BB%84_1074_laccmo.webp'
                                : 'https://res.cloudinary.com/home-space/image/upload/v1668031694/Products/Hspace%20Special%20Deals/2022_Boxing_Month_Promotion/%E6%B4%BB%E5%8A%A8%E9%A1%B5_pg2g6r.webp'
                        }
                        )`,
                    }}
                >
                    <div className="boxing_text_box">
                        <div className="boxing_text_area">
                            <h3>Boxing Month Promotion</h3>
                            <p>
                                Buy More Save More: Save Up to 40% on All
                                In-Stock Custom Cabinet
                            </p>
                            <p>2022.12.01 - 2023.01.01</p>
                            <p style={{ fontWeight: '700' }}>
                                Promo Code: BMSM2022
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Container>
                <div className="boxing-promo-box">
                    {promoList.map((promo) => (
                        <div
                            key={promo.id}
                            className="boxing-card"
                            onClick={() => history.push('/cabinets')}
                        >
                            <div className="boxing-color-light"></div>
                            <div
                                className="boxing-color-box"
                                style={{ backgroundColor: promo.boxColor }}
                            ></div>
                            <div className="boxing-title">{promo.title}</div>
                            <div className="boxing-middle">
                                <h1>{promo.promo}</h1>
                                <h3>OFF</h3>
                            </div>
                            <div className="boxing-color-square"></div>
                        </div>
                    ))}
                </div>
                <div className="boxing-policy">
                    <h4>Return/Exchange/Cancellation/Mistake：</h4>
                    <div className="boxing-text">
                        HS-Online.com has a NO RETURN policy on cabinet parts.
                        All cabinet orders are built to order and are
                        manufactured particularly for each customer.
                        HS-Online.com also does not accept cancellations, so
                        please review all orders carefully. Should an order be
                        damaged (scratches by knives or sharp objects, impact
                        during handling, drop damage to the cabinet door/cabinet
                        body, etc.), returns may be accepted. All product
                        returns must be approved by HS-Online.com. Email
                        info@hs-online.com (include receipt, order NO., &
                        account ID) for approval and return instructions.
                        Customer Service will reply within 24 hours.
                    </div>
                    <div className="boxing-text">
                        Customers will be subjected to a 20% restocking fee on
                        all items returned and will be responsible to pay the
                        return shipping. No returns are approved after 20 days
                        of receiving your order, and any missing or damaged
                        items must be reported within 3 business days of
                        receiving the order. Once an order has been processed,
                        any cancellation or exchange will charge 20% restocking
                        fee.
                    </div>
                    <div className="boxing-text">
                        For product exchanges, please follow our return process.
                        Customers can repurchase at HS-Online.com. Return
                        requests must be approved by HS-Online.com, without any
                        artificial damages (scratches, impact, drop damages,
                        etc.)
                    </div>
                </div>
                <DesignCenter />
            </Container>
        </>
    )
}

export default BoxingMonthPromotion
