import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { promoCodeList } from '../../constant/promoCode'
import { generateNum } from '../../service/generateNum'
import { checkPromoCode } from '../../store/actions/cartActions'

const PrintCartDetails = () => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { carts, promoCode, promoRate } = cartState

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    // const [ip, setIp] = useState('')

    // useEffect(() => {
    //     clientIP()
    // }, [])

    useEffect(() => {
        if (promoCode) {
            const values = { promoCode }
            dispatch(checkPromoCode(values))
        }
    }, [dispatch, promoCode])

    // const clientIP = async () => {
    //     try {
    //         const res = await axios.get('https://geolocation-db.com/json/')
    //         setIp(res.data.IPv4)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const subTotal = useMemo(() => {
        return carts.reduce((a, b) => (a += b.price * b.qty), 0)
    }, [carts])

    const printNum = useMemo(() => {
        const dateNum = generateNum()
        const clientName = clientInfo
            ? `- ${clientInfo?.firstName} ${clientInfo?.lastName}`
            : ''
        return `${dateNum} ${clientName}`
    }, [clientInfo])

    const subPromoTotal = useMemo(() => {
        let result = 0
        if (promoCode === promoCodeList.BOGO) {
            result =
                carts.reduce(
                    (a, b) => (a += b.price * parseInt(b.promoActive)),
                    0
                ) || 0
        }
        if (promoCode === promoCodeList.BMSM2022) {
            result = carts
                .filter((item) => item.promoActive === 1)
                .reduce(
                    (sum, product) =>
                        sum +
                        Number(
                            (
                                product.qty *
                                (product.price * (promoRate / 100))
                            ).toFixed(2)
                        ),
                    0
                )
        }
        return result
    }, [carts, promoCode, promoRate])
    const allItems = carts.reduce((a, b) => (a += parseInt(b.qty)), 0)

    const promoDisplay = (cart) => {
        let result = ''
        if (promoCode === promoCodeList.BOGO) {
            result = (
                <>
                    <div className="printcart-list-price">
                        $
                        {(cart.price * (cart.qty - cart.promoActive)).toFixed(
                            2
                        )}
                    </div>
                    <div className="printcart-list-promo-delete">
                        ({promoCode} -$
                        {(cart.price * cart.promoActive).toFixed(2)})
                    </div>
                </>
            )
        }
        if (promoCode === promoCodeList.BMSM2022) {
            result = (
                <>
                    <div className="printcart-list-price">
                        $
                        {(
                            cart.qty *
                            ((cart.price * (100 - promoRate)) / 100)
                        ).toFixed(2)}
                    </div>
                    <div className="printcart-list-promo-delete">
                        ({promoCode} -$
                        {(cart.qty * ((cart.price * promoRate) / 100)).toFixed(
                            2
                        )}
                        )
                    </div>
                </>
            )
        }

        return result
    }

    return (
        <>
            <div className="printcart-button-box">
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1631537972/Logo/HomeSpace_Logo_ceragm.svg"
                    alt="logo"
                    className="printcart-logo"
                />
            </div>
            <div className="printcart-button-box">
                <h3>TIEMS IN CART ({allItems})</h3>
            </div>
            <div style={{ textAlign: 'center' }}>Print No: {printNum}</div>
            <div className="printcart-container">
                <div className="printcart-lists">
                    {carts &&
                        carts.map((cart) => (
                            <React.Fragment key={cart.id}>
                                <div className="page-break"></div>
                                <div className="printcart-list-box">
                                    <div className="printcart-list-pic">
                                        <img src={cart.picLink} alt="Product" />
                                    </div>
                                    <div className="printcart-list-name-box">
                                        <div className="printcart-list-name-name">
                                            {cart.name}
                                        </div>
                                        <div className="printcart-list-name-size">
                                            {' '}
                                            {cart.productWidth}" W x{' '}
                                            {cart.productHeight}" H x{' '}
                                            {cart.productDepth}" D
                                        </div>
                                        <div className="printcart-list-name-size">
                                            Unit Price: ${cart.price}
                                        </div>
                                        <div className="printcart-list-name-skuno">
                                            Article Number: {cart.skuNo}
                                        </div>
                                        {cart.doorDirection && (
                                            <div className="printcart-list-option-title">
                                                Door Hinge Location:{' '}
                                                <span className="printcart-list-option-detail">
                                                    {cart.doorDirection}
                                                </span>
                                            </div>
                                        )}

                                        {cart.glassOption && (
                                            <>
                                                <div className="printcart-list-option-title">
                                                    Glass Doors:{' '}
                                                    <span className="printcart-list-option-detail">
                                                        {
                                                            cart.glassOption.split(
                                                                '&'
                                                            )[2]
                                                        }
                                                    </span>
                                                </div>
                                            </>
                                        )}

                                        {cart.depthOption && (
                                            <>
                                                <div className="printcart-list-option-title">
                                                    Change Cabinet Depth:{' '}
                                                    <span className="printcart-list-option-detail">
                                                        {
                                                            cart.depthOption.split(
                                                                '&'
                                                            )[2]
                                                        }
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="printcart-list-right">
                                        <div className="printcart-list-qty">
                                            Qty: {cart.qty}
                                        </div>

                                        {parseFloat(cart?.promoActive) > 0 ? (
                                            promoDisplay(cart)
                                        ) : (
                                            <div className="printcart-list-price">
                                                $
                                                {(
                                                    cart.price * cart.qty
                                                ).toFixed(2)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                </div>
                <div className="printcart-total-box">
                    <div className="page-break"></div>
                    <div className="printcart-total">
                        <div className="printcart-total-item">
                            <span className="printcart-total-span-left">
                                <strong>Merchandise Subtotal</strong>
                            </span>
                            <span className="printcart-total-span-right">
                                <strong>${subTotal}</strong>
                            </span>
                        </div>
                        {subPromoTotal > 0 && promoCode && (
                            <div className="printcart-total-item">
                                <span className="printcart-total-span-left">
                                    Promo Code {promoCode}{' '}
                                    {promoRate ? `-${promoRate}%` : ''}
                                </span>
                                <span className="printcart-total-span-right">
                                    - ${subPromoTotal.toFixed(2)}
                                </span>
                            </div>
                        )}
                        <div className="printcart-total-item">
                            <span className="printcart-total-span-left">
                                GST/HST
                            </span>
                            <span className="printcart-total-span-right">
                                $
                                {((subTotal - subPromoTotal) * 0.13).toFixed(2)}
                            </span>
                        </div>
                        <div className="printcart-total-item">
                            <span className="printcart-total-span-left">
                                Total
                            </span>
                            <span className="printcart-total-span-right">
                                $
                                {((subTotal - subPromoTotal) * 1.13).toFixed(2)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrintCartDetails
