export const productCategories = [
    {
        id: 1,
        index: 1,
        // colorId: process.env.REACT_APP_SHAKER_WHITE_ID,
        styleColor: 'Transitional Style',
        pageLink: '/products/cabinets/transitional',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/c_crop,g_custom:faces/v1650572620/Pictures_Kitchen/SW-F-Kitchen_2/WeChat_Image_20220414145631_ao1n9n.webp',
    },
    {
        id: 2,
        index: 2,
        // colorId: process.env.REACT_APP_SHAKER_GREY_ID,
        styleColor: 'Classic Style',
        pageLink: '/products/cabinets/classic',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/c_crop,g_custom:face/v1651160089/Pictures_Kitchen/SG-F-Kitchen_2/kitchen_lait_grey_shaker-1-min_xjj9d3_mkjssh.webp',
    },
    {
        id: 3,
        index: 3,
        // colorId: process.env.REACT_APP_SHAKER_SPACE_GREY_ID,
        styleColor: 'Modern Style',
        pageLink: '/comingsoon',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1677595888/Pictures_Kitchen/MODERN/pexels-max-rahubovskiy-7614542_yntpor.webp',
    },
    {
        id: 4,
        index: 4,
        // colorId: process.env.REACT_APP_WILLOW_CHARCOAL_ID,
        styleColor: 'Vanity Style',
        pageLink: '/products/cabinets/vanity',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1643379380/Pictures_Bathroom/Bathroom_5_SW/SW7_pyf4dd.webp',
    },
    {
        id: 5,
        index: 5,
        styleColor: 'Handles and Knobs',
        pageLink: '/products/accessories/handles',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1655131040/Products/Handles/Aurora-P629/5227ee91b2f0fceb48fee9265fbeb0e_fhd35r.webp',
    },
    {
        id: 6,
        index: 6,
        styleColor: 'Home Office',
        pageLink: '/products/accessories/office',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1657719090/Products/Home%20Office/Mobile%20storage%20cube%20with%203%20drawer/White/%E4%B8%89%E9%97%A8%E7%99%BD%E8%89%B2_k1nnuy.webp',
    },
    {
        id: 7,
        index: 7,
        styleColor: 'Photo Frames',
        pageLink: 'products/homedecor/photo-frames',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1657116776/Products/Decor/Photo%20Frames/MUZI%20Collection/Wood%20Shadow%20Box/Brown/402035-6_cj8gpp.webp',
    },
    {
        id: 8,
        index: 8,
        styleColor: 'Mirrors',
        pageLink: '/products/homedecor/mirrors',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1658411203/Products/Decor/Mirror/Frame%20Mirror/Aluminum%20Round%20Mirror/81JbjFICsfS._AC_SL1500__1_ydncfq.webp',
    },
]
