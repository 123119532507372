import React from 'react'

const DesignCenter = () => {
    return (
        <div className="home_design_center_container">
            <div className="home_design_center_bgpic">
                <div className="home_design_center_text_box">
                    <div className="home_design_center_text_logo_box">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1651435403/Logo/2020_group_2x_jufdah.png"
                            alt="design_logo"
                            className="home_design_center_text_logo"
                        />
                    </div>
                    <div className="home_design_center_text_area">
                        <h3>Design Center</h3>
                        <p>
                            View accurate cabinet measurements and real
                            aesthetics to design the kitchen of your dreams
                            before getting help from experts.
                        </p>
                    </div>
                    <div className="home_design_center_button_box">
                        <div className="home_design_center_button_left">
                            <a
                                href="https://idealspaces-hspace-prod.2020-platform.com/index.html#inspiration/category/KITCHEN/presetsStyles"
                                target="_black"
                                rel="noreferrer"
                            >
                                <button className="home_design_center_button">
                                    Guide Me To A Kitchen
                                </button>
                            </a>
                        </div>
                        <div className="home_design_center_button_middle">
                            Or
                        </div>
                        <div className="home_design_center_button_right">
                            <a
                                href="https://idealspaces-hspace-prod.2020-platform.com/index.html#projects/anonymous/design/Kitchen"
                                target="_black"
                                rel="noreferrer"
                            >
                                <button className="home_design_center_button">
                                    Create From Scratch
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesignCenter
